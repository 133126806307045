.hero {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 8px;
    border-radius: 48px;
    background: var(--mountain-meadow-100, #D1FAEC);
    margin-top: 24px;
    height: 560px;
    overflow: hidden;
    margin: 24px 0;
}

.heroLeft {
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 568px;
}

.heroTitle {
    color: var(--mountain-meadow-950, #022C1E);
    font-family: 'Avenir';
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    display: flex;
    margin: 0px;
    padding: 0px;
    letter-spacing: -1.44px;
}

.heroText {
    display: flex;
    margin: 0px;
    color: var(--mountain-meadow-950, #022C1E);
    font-family: 'Avenir';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.16px;
    margin-top: 12px;
}

.heroRight {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    margin: 24px;
    gap: 8px;
    flex: 1;
    border-radius: 24px;
    overflow: hidden;
    min-width: fit-content;
}

.squareDummy {
    flex: 1;
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: 1s;
}

.squares {
    flex: 1;
    border-radius: 4px;
    position: relative;
    color: #FFF;
    z-index: 0;
    text-align: center;
    font-family: 'Avenir';
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.6px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

.squares * {
    cursor: pointer;
}

.squareText {
    max-width: 128px;
}

.squareImg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -10;
    transition: 2s;
    object-fit: cover;
}

.zoom {
    transform: scale(1.1)
}

@media (max-width: 1024px) {
    .hero {
        flex-direction: column;
        height: fit-content;
        max-width: fit-content;
        margin: auto;
        margin-top: 42px;
        align-items: center;
    }

    .heroLeft {
        align-items: center;
        text-align: center;
        width: 60%;
        margin: auto;
        padding: 0px;
        margin: 32px 0;
    }

    .heroRight {
        padding: 0px;
    }

    .squares {
        height: 150px;
        aspect-ratio: 1;
    }
}

@media (max-width: 480px) {
    .hero {
        flex-direction: column;
        height: fit-content;
        max-width: fit-content;
        margin: auto;
        margin-top: 42px;
        align-items: center;
    }

    .heroLeft {
        width: 80%;
    }

    .heroRight {
        padding: 0px;
    }

    .squares {
        height: 90px;
        min-width: 90px;
        aspect-ratio: 1;
    }

    .squareText {
        font-size: 12px;
        max-width: 70%;
    }
}