:root {
  --green: #10B981;
  --softGreen: #D1FAEC;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  overflow-y: scroll;
}

.body {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.baseButton {
  border-radius: 18px;
  background: var(--mountain-meadow-500, #10B981);
  padding: 8px 12px;
  padding-left: 18px;
  display: flex;
  border: none;
  cursor: pointer;
  max-width: fit-content;
  color: var(--marshland-50, #F6F7F6);
  text-align: center;
  font-family: 'Avenir';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;
  gap: 8px;
  flex-direction: row;
  align-items: center;
}

.contentRow {
  padding: 48px 0;
}

.contentRowLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin: 32px;
}

.contentRowImage {
  max-width: 596px;
  max-height: 500px;
  min-width: 280px;
  width: 60%;
  aspect-ratio: 596/500;
  border-radius: 32px;
  object-fit: cover;
}

.blogCardsRow {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.blogCard {
  display: flex;
  flex-direction: column;
  padding: 4px;
  max-width: 282px;
  min-width: 282px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 6px 32px -4px rgba(3, 37, 26, 0.2);
  cursor: pointer;
  transition: 0.5s;
}

.blogImg {
  height: 200px;
  max-height: 200px;
  width: calc(100% - 8px);
  max-width: calc(100% - 8px);
  margin: 4px;
  object-fit: cover;
  border-radius: 8px;
  transition: 0.5s;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}

.blogCard:hover {
  transform: scale(1.01);
  box-shadow: 0px 6px 32px -4px rgba(3, 37, 26, 0.7);
}

.blogCategory {
  overflow: hidden;
  color: var(--marshland-400, #81857C);
  text-overflow: ellipsis;
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 21px;
  letter-spacing: 3.36px;
  text-transform: uppercase;
  margin: 16px;
}

.blogTitle {
  color: var(--marshland-950, #171816);
  font-family: 'Avenir';
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.2px;
  margin: 12px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.lastWritten {
  color: var(--mountain-meadow-950, #022C1E);
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.96px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: #022C1E;
  padding-bottom: 8px;
  font-size: 32px;
  margin-bottom: 24px;
  margin-top: 72px;
}

.blogButtonFrame {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 72px;

}

.blogButtonLine {
  flex: 1;
  border-style: solid;
  border-width: 0.5px;
  border-color: var(--marshland-100, #E4E5E2);
}

.blogButton {
  margin: 0 12px;
  padding: 8px 12px;
  color: var(--mountain-meadow-600, #059666);
  text-align: center;
  font-family: 'Avenir';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;
  border-radius: 18px;
  border: 1px solid var(--mountain-meadow-600, #059666);
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding-left: 18px;
  cursor: pointer;
}

.belt {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto 0 auto;
  height: 183px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--marshland-50, #F6F7F6);
  text-align: center;
  font-family: 'Avenir';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.2px;
  position: relative;
  background-image: url('https://images.unsplash.com/photo-1543362906-acfc16c67564?auto=format&fit=crop&q=80&w=2865&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  /* Görüntü yolunu burada belirtin */
  background-size: cover;
  /* Görüntüyü div'e sığacak şekilde ölçekle */
  background-position: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.belt article {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #065F4299;
}

.beltTitle {
  display: flex;
  z-index: 11;
}

.aboutText {
  color: var(--marshland-600, #51544D);
  font-family: 'Avenir';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  /* 150% */
  letter-spacing: -0.18px;
  display: flex;
  flex-direction: column;
  max-width: 588px;
}

.aboutImg {
  width: 588px;
  background-size: cover;
  border-radius: 32px;
  background-position: center;
  height: 815px;
  display: flex;
  object-fit: cover;
  object-position: center;
}

.aboutImgBlog {
  width: 588px;
  background-size: cover !important;
  border-radius: 32px;
  background-position: center;
  height: 815px;
  display: flex;
}

.blogLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1
}

.blogsPlace {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: fit-content;
  gap: 24px;
}

.blogRight {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 282px;
  gap: 24px;
}

.blogBottomFrame {
  display: flex;
  width: calc(100% - 32px);
  margin: auto;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 12px;
}

.catFrame {
  border-radius: 12px;
  background: var(--mountain-meadow-200, #A7F3DA);
  display: flex;
  flex-direction: column;
  min-height: 12px;
  padding: 24px;
  gap: 8px;
}

.blogInput {
  max-width: 267px !important;
  border-width: 1px !important;
}

.mobileSearch {
  display: none !important;
}

.catSection {
  color: var(--mountain-meadow-900, #064E36);
  font-family: 'Avenir';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.72px;
  max-width: 100%;
  word-wrap: break-word;
}

.categories {
  color: var(--marshland-400, #81857C);
  font-family: 'Avenir';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  cursor: pointer;
  letter-spacing: -0.14px;
}

.categories:hover {
  text-decoration: underline;
}

.adds {
  background-image: url('https://s3-alpha-sig.figma.com/img/dfaa/1789/eedc6c548ccb99c7af3541560770fb13?Expires=1698019200&Signature=NStGBjJVb63VhJUlwbhZLA0VDOFgsDRYDkf5OZbcsEld3I8Y5MobjYzt3nnKCTXNEfldGjfWHaaZts2hPus~T92JLX69VeDqps0rV6y8SLFxTYrag8wlOPtjCO7QnyGbsyGCMcGmCnlyX0Wa-o3XuCgZWjDOFnre8ADch~HM~uCTBNKa4GuI9ycvRR6wRwhARecaScdBKpQezvbqQwSHZBvg9goy1Csw77JNND32g2mXdVJxXK0u-8D2Getns~Rzhk1kGLP1eref6KqPFnk02~7KLKaYlj7MC9DNKXm61YAWo9fGLg5y-6koT926Sm9UtpuK7420FE9AcH-qCsbxbQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4');
  background-position: center;
  background-size: cover;
  display: flex;
  width: 100%;
  height: 400px;
  border-radius: 12px;
}

.blogDesc {
  color: var(--marshland-600, #51544D);
  font-family: 'Avenir';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.18px;
  max-width: 878px;
}

.blogDetail {
  gap: 32px;
  align-items: flex-start !important;
  margin-bottom: 72px;
}

.blogDetailLeft {
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
}

.blogDetailRight {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.minwblogCard {
  min-width: 274px !important;
}

.blogDetailTitle {
  color: var(--Mountain-Meadow-950, #022C1E);
  font-family: Avenir;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.96px;
}

.blogDetailImg {
  max-width: 878px;
  width: 100%;
  display: flex;
  height: 400px;
  object-fit: cover !important;
  border-radius: 24px;
}

.blogDetailBottom {
  display: none !important;
}

.arrowReverse {
  transform: scaleX(-1);
}

.blogCategory {
  min-height: 44px;
}

@media (max-width:1024px) {
  .blogDetail {
    margin-bottom: 0;
  }

  .blogDetailLeft {
    margin: auto;
  }

  .blogDetailRight {
    display: none;
  }

  .blogDetailImg {
    min-width: 100%;
    max-width: 100%;
  }

  .blogDetailBottom {
    display: flex !important;
  }

  .mobileSearch {
    display: flex !important;
    min-width: calc(100% - 32px) !important;
    border-width: 1px !important;
  }

  .contentRow {
    flex-direction: column !important;
    align-items: center !important;
    text-align: center;
  }

  .contentRowLeft {
    align-items: center;
    order: 0 !important;
    max-width: 70%;
  }

  .blogCardsRow {
    width: 100%;
    padding: 0 16px;
    overflow: hidden;
    overflow-x: scroll;
    padding: 24px 0;
  }

  .aboutRow {
    flex-direction: column !important;
  }

  .aboutImg {
    order: 1;
  }

  .aboutText {
    order: 2;
    text-align: center;
    max-width: 75%;
  }

  .aboutImg {
    max-width: calc(100% - 32px);
    max-height: 400px;
    margin-bottom: 42px;
  }

  .aboutContent .contentRowImage {
    order: 0 !important;
  }

  .aboutContent .contentRowLeft {
    order: 1 !important;
  }

  .blogRight {
    display: none;
  }

  .blogLeft .blogCard {
    max-width: 45% !important;
    flex-wrap: nowrap !important;
    margin: auto;
  }
}


@media (max-width:480px) {
  .blogLeft {
    width: calc(100% - 32px);
    padding: 0 16px;
  }

  .contentRowImage {
    min-width: calc(100% - 32px);
    object-position: center;
  }

  .aboutText {
    order: 2;
    text-align: center;
    max-width: calc(100% - 32px);
  }

  .aboutImg {
    margin-top: 0px;
  }

  .blogLeft .blogCard {
    min-width: 200px !important;
    max-width: calc(100% - 150px) !important;
    flex-wrap: nowrap !important;
    margin: auto;
  }
}