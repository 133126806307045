nav {
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 12px 0 0 0;
    position: fixed;
    width: 100%;
    top: 0px;
    background-color: white;
    z-index: 99;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #E4E5E2;
}

.navItems {
    display: flex;
    padding: 16px 12px;
    border-bottom-style: solid;
    border-width: 1px;
    cursor: pointer;
    margin-bottom: -1px;
}

.navFrame {
    display: flex;
    flex-direction: row;
}

.langFrame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left-style: solid;
    border-width: 1px;
    border-color: #E4E5E2;
    height: fit-content;
    margin: auto;
    padding-left: 12px;
    color: var(--marshland-950, #171816);
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
}

.langButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    color: var(--marshland-950, #171816);
    font-family: 'Avenir';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
/* .langButton:hover~.langMenu {
    display: flex;
}
.langButton:focus~.langMenu {
    display: flex;
} */

.langMenu:hover {
    display: flex;
}

.langMenu {
    position: absolute;
    top: 30px;
    padding: 4px;
    width: 116px;
    background-color: white;
    left: 0px;
    border-radius: 16px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    display: none;
    flex-direction: column;
}

.langButtons {
    width: 100%;
    padding: 8px 12px;
    border-radius: 12px;
    color: var(--marshland-950, #171816);
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.selectedLang {
    background: var(--marshland-50, #F6F7F6);
    color: #10B981 !important
}
.navLogo{
    font-family: Condiment;
    font-size: large;
}