footer {
    min-height: 100px;
    align-items: center;
    display: flex;
    flex-direction: row;
    background: linear-gradient(180deg, rgba(250, 250, 250, 0.00) 0%, #A7F3DA 100%);
    padding: 48px 0;
}

.footerTitle {
    color: var(--mountain-meadow-900, #064E36);
    text-align: center;
    font-family: 'Avenir';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    /* 125% */
    letter-spacing: -0.72px;
}

.footerSubtext {
    color: var(--marshland-400, #81857C);
    text-align: center;
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 150% */
    letter-spacing: -0.14px;
    margin: 12px 0;
}

.footerInputFrame {
    width: 100%;
    max-width: 588px;
    display: flex;
    flex-direction: row;
    border-radius: 24px;
    overflow: hidden;
    padding: 6px;
    background-color: white;
}

.footerButton {
    padding: 8px 12px;
    padding-left: 18px;
    border-radius: 18px;
    background: linear-gradient(95deg, #34D39E -0.06%, #10B981 99.94%);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: var(--marshland-50, #F6F7F6);
    text-align: center;
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
    gap: 6px
}

.footerInput {
    flex: 1;
    border: none;
    padding-left: 12px;
    color: var(--marshland-400, #81857C);
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.42px;
}

.footerInput:focus {
    outline: none;
}

.footerLogo {
    margin: 32px 0 16px 0;
    height: 38px;
}

.linksFrame {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 16px;
}

.links {
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
}

.copyright {
    color: var(--marshland-400, #81857C);
    text-align: center;
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.42px;
}